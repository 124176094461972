import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Resellers = ({ data }) => {
  return (
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoChcnav.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoHorus.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <div className="img-fluid text-gray-600 mb-2 mb-md-0">
            <Img fluid={data.logoAllTerraDS.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoTPI.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoViaTech.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  )
}

export const logo = graphql`
  fragment logoResellers on File {
    childImageSharp {
      fluid(maxWidth: 250) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const reseller = (props) => (
  <StaticQuery
    query={graphql`
      query {
        logoAllTerraDS: file(relativePath: { eq: "allterra-ds-logo.jpg" }) {
          ...logoResellers
        }
        logoHorus: file(relativePath: { eq: "horus-logo.png" }) {
          ...logoResellers
        }
        logoViaTech: file(relativePath: { eq: "viatech-logo.png" }) {
          ...logoResellers
        }
        logoTPI: file(relativePath: { eq: "tpi-logo.png" }) {
          ...logoResellers
        }
        logoChcnav: file(relativePath: { eq: "chcnav-logo.png" }) {
          ...logoResellers
        }
      }
    `}
    render={(data) => <Resellers data={data} {...props} />}
  />
)

export default reseller
